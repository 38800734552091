<template>
  <div class="container">
    <div class="row search">
      <div
        v-for="(item, idx) in genre"
        :key="idx"
        class="search-params_type col-6  col-sm-4"
      >
        <input
          :id="item.genreType"
          v-model="picked"
          :type="item.type"
          :name="item.inputName"
          :value="item.genreType"
          @change="picFilms"
        />
        <label :for="item.genreType">{{ item.value }}</label>
      </div>
      <div class="search-date_wrap col-12  col-sm-4">
        <div class="search-date_inner">
          <select v-model="currentYear" @change="picFilms">
            <option value="" disabled>Дата премьеры</option>
            <option v-for="item in baseYear" :key="item"> {{ item }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: 1900,
      baseYear: [],
      picked: "movie",
      currentYear: "",
      genre: [
        {
          genreType: "movie",
          type: "radio",
          inputName: "film",
          value: "Кино",
        },
        {
          genreType: "tv",
          type: "radio",
          inputName: "film",
          value: "Сериал",
        },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < new Date().getFullYear() - this.date + 1; i++) {
      this.baseYear.unshift(this.date + i);
    }
    this.$emit("ready-state", {
      picked: this.picked,
      date: this.date,
      currentYear: this.currentYear,
    });
  },
  methods: {
    picFilms() {
      this.$emit("select-changed", {
        picked: this.picked,
        date: this.date,
        currentYear: this.currentYear,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/css/includes/_general";
@import "../assets/css/style";

.search {
  font-size: 1.25rem;
  padding: 1.5rem 0;

  &-params_type,
  &-date_inner {
    text-align: center;
    @include resolve(xs) {
      text-align: left;
    }
    @include resolve(md) {
      text-align: center;
    }
  }
  &-params_type {
    margin-top: 1rem;
    order: 1;
    @include resolve(xs) {
      order: 0;
      margin-top: 0;
    }
  }
  &-params_type > label {
    margin-left: 1rem;
  }
}
</style>
